
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import IconLock from '@/components/Icon/IconLock.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { createLinkAccount } from '@/apis/ApiBank';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { replaceCharacterLatin } from '@/utils/index';
import constRouter from '@/constants/constRouter';
export default defineComponent({
  components: {
    DefaultLayout,
    BaseInput,
    IconLock,
    BaseButton,
    BaseModal,
    BaseTextError,
  },
  setup() {
    const accountNumber = ref('');
    const accountName = ref('');
    const nicNumber = ref('');
    const phoneNumber = ref('');
    const openModal = ref(false);
    const dataModal = ref({});
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const errorMessages = ref('');
    const user = store.getters.getUser;
    const bank = store.getters['bank/getBankChooseLink'];
    nicNumber.value = user.nicNumber;
    phoneNumber.value = user.phoneNumber;
    if (!bank.name) {
      router.push({
        name: constRouter.DASHBOARD.name,
      });
    }
    return {
      user,
      bank,
      accountNumber,
      accountName,
      nicNumber,
      phoneNumber,
      openModal,
      dataModal,
      loading,
      errorMessages,
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      createLinkAccount(
        {
          binCode: this.bank.binCode,
          name: replaceCharacterLatin(this.accountName),
          nicNumber: this.nicNumber,
          accountNumber: this.accountNumber,
          phoneNumber: this.phoneNumber,
          callBackUrl: window.location.origin + '/bank-account',
        },
        this.bank.binCode,
      )
        .then((res) => {
          location.href = res.data.redirectLink;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.openModal = true;
          this.dataModal = err?.data?.message;
        });
    },
    closeModal() {
      this.openModal = false;
    },
  },
});
